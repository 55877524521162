import React, {useContext, useEffect, useState} from "react";
import {ToastContext} from "../components/ToastProvider";
import {Button, Form, Input, Progress, Select, Space, Spin, Steps, Typography, Upload} from "antd";
import {TranslateContext} from "../../utils/TranslateProvider";


import {FolderAddOutlined, InboxOutlined, LoadingOutlined} from "@ant-design/icons";
import FileListItemRender from "./FileListItemRender";
import useFetch from "../../../Hook/useFecth";
import useCheckFile from "../../../Hook/useCheckFile";
import useUpload from "../../../Hook/useUpload";
import Resumable from "resumable";

const {Text} = Typography;

const BASE_URL = process.env.REACT_APP_BASE_URL;
//max file size 2gb
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Dragger } = Upload;
function submitButtonComponent(zipUploaded, pdfUploaded, reference, newAlbum, t) {
    return () => {
        const [submittable, setSubmittable] = useState(false);
        useEffect(() => {
            // console.log("zip:"+zipUploaded +' pdf:'+ pdfUploaded +' ref :'+reference +' newAlbum :'+ newAlbum);
            if (zipUploaded && pdfUploaded && (reference || newAlbum)) {
                setSubmittable(true);
            } else {
                setSubmittable(false);
            }
        }, [zipUploaded, pdfUploaded, reference, newAlbum]);

        return (
            <Button type="primary" htmlType="submit" disabled={!submittable}>
                {t('upload')}
            </Button>
        );
    };
}
function OperationSelector(setOperation, t) {
    return () => {
        return (
            <Form.Item>
                <Space wrap={true}>
                    <Button size={'large'} type="primary" onClick={(e) => {
                        e.preventDefault();
                        setOperation('newMaster')
                    }

                    }>{t('newMaster')}</Button>
                    <Button size={'large'} type="primary" onClick={(e) => {
                        e.preventDefault();
                        setOperation('replaceMaster')
                    }
                    }>{t('replaceMaster')}</Button>
                </Space>

            </Form.Item>
        )
    };
}


//todo a refactoriser
export default function UploadForm() {
    const toast = useContext(ToastContext);
    const [form] = Form.useForm();
    const [zipUploaded, setZipUploaded] = useState(false);
    const [pdfUploaded, setPdfUploaded] = useState(false);
    const[reference, setReference] = useState(false);
    const [newAlbum, setNewAlbum] = useState(false);
    const [referenceRequired, setReferenceRequired] = useState(true);
    const [newAlbumRequired, setNewAlbumRequired] = useState(true);
    const {t} = useContext(TranslateContext);

    const [step, setStep] = useState(0);
    const formData = new FormData();
    const [title, setTitle] = useState(null);

    const [zipFileList, setZipFileList] = useState([]);
    const [pdfFileList, setPdfFileList] = useState([]);
    const [operation, setOperation] = useState('');

    const [loading, error, data, , , , fetchData] = useFetch(`${BASE_URL}/get-reference`)
    const [loadingDetail, errorDetail, dataDetail, , , , fetchDataDetail] = useFetch(`${BASE_URL}/get-reference-detail`)
    const SubmitButton = submitButtonComponent(zipUploaded, pdfUploaded, reference, newAlbum, t);
    const [submitPDF,
        submitZIP,
        response ,
        cancelUploadHandler,
        uploadProgress,
        cancelUpload,
        speed,
        uploadProgressZIP,
        errorFile,
    uploadSuccess] = useUpload(toast,t)
    const [existingReference, setExistingReference] = useState([]);





    useEffect(() => {
        if (data) {
            //remove element with name . and ..
            data.splice(0, 2);
            setExistingReference(data);
        }
    }, [data]);

    useEffect(() => {
        if (dataDetail) {
            setExistingReference(dataDetail);
        }
    }, [dataDetail]);



    const reinit = () => {
        fetchData();
        form.resetFields();
        setOperation('')
        setZipUploaded(false);
        setPdfUploaded(false);
        setReference(false);
        setNewAlbum(false);
        setStep(0);
        setTitle(null);
        setZipFileList([]);
        setPdfFileList([]);

    }

    const [checkFileName, checkFileSize] = useCheckFile(t);
    const itemRender =  FileListItemRender();
    const OperationSelection = OperationSelector(setOperation, t);

    const [errorMessages, setErrorMessages] = useState('');

    //init steps
    const stepsItem =[
        {
            title:  <p className='d-none d-lg-block d-sm-none'>{title ? 'projet : ' + title : t('selectProject')}</p>,
            content: <>
                {operation === '' ? <OperationSelection /> : null}

               <Form.Item
                  style={{ display: operation === 'newMaster' ? 'block' : 'none' }}
                  name="newAlbum"
                  rules={[
                      { required: newAlbumRequired, message: t('newRefRules') },
                      //custom to check if this name is UTF-8
                      { pattern: /^[A-Za-z0-9]*$/, message:'erreur' }
                  ]}
                >
                    <Text className="d-block mb-3">{t('createNewProject')}</Text>
                    <Input
                        // value={title}

                        onChange={(event) => {
                            if (event.target.value !== '') {
                                console.log(event.target.value)
                                const isok = /^[A-Za-z0-9]*$/.test(event.target.value);
                                if(isok) {
                                    setTitle(event.target.value)
                                    setNewAlbum(true);
                                    setReferenceRequired(false);
                                    form.resetFields(['reference']);
                                }else{
                                    setTitle(null)
                                    setNewAlbum(false);
                                    setReferenceRequired(true);
                                    setErrorMessages('Le nom du projet contient des caractères non autorisés')
                                    }
                            } else {
                                setTitle(null)
                                setReference(false);
                                setNewAlbum(false);
                                setReferenceRequired(true);
                                setErrorMessages('')
                            }
                        }}

                        allowClear
                        style={{ width: 300 }}
                        prefix={<FolderAddOutlined className="site-form-item-icon" />}
                        placeholder= {t('newRef')}
                    />
                </Form.Item>
                {/*//error message*/}
                <Text>{errorMessages}</Text>
                <Form.Item
                    name="reference"
                    style={{ display: operation === 'replaceMaster' ? 'block' : 'none' }}
                    rules={[{ required: referenceRequired, message: t('selectRef') }]}
                >
                    <Text className="d-block mb-3">{t('selectProject')}</Text>
                    <Text style={{opacity:'0.5'}} className="d-block mb-3">  {t('selectProjectNote')}</Text>
                    <Select
                        onChange={(value) => {
                            if(value !== undefined){
                                setTitle(value)
                                setReference(true);
                                setNewAlbumRequired(false);
                                form.resetFields(['newAlbum']);
                                //change default value of current select field
                            }else {
                                setTitle(null)
                                setNewAlbum(false)
                                setReference(false);
                                setNewAlbumRequired(true);
                            }

                        }}
                        value={title}
                        showSearch
                        loading={loading || loadingDetail}
                        allowClear
                        style={{ width: 300 }}
                        placeholder={ t('selectRef')}
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={existingReference.map((item) => ({ label: item.name + (item.list?' - V'+item.list:''), value: item.name }))}
                    />
                </Form.Item>

                {operation === '' ? null :
                <Form.Item>
                    <Space>
                        <Button type="primary"  onClick={()=>{setOperation('')}} >{t('previous')}</Button>
                        <Button type="primary"  onClick={()=>{setStep(step+1)}} disabled={!(reference || newAlbum)}>{t('next')}</Button>
                    </Space>
                </Form.Item>}




            </>
        },
        {
            title: <p className='d-none d-lg-block d-sm-none'>{ t('selectFile')}</p>,
            content: <>
                <Form.Item name="zip" >
                    <Dragger
                        onPreview={fetchDataDetail}
                        itemRender={(originNode, file, currFileList) => itemRender(originNode, file, currFileList, setZipFileList, setZipUploaded)}
                        showUploadList={zipUploaded}
                        fileList={zipFileList}
                        onChange={({ fileList }) => setZipFileList(fileList)}
                        beforeUpload={(file) => {
                            const isUTF8 = checkFileName(file);
                            const isSize = checkFileSize(file);

                            console.log(file.type)
                            const isZip = file.type.includes('zip');
                            if (!isZip) {
                                //remove file
                                setZipFileList([]);
                                toast.error(t('onlyZIP'),15);
                                setZipUploaded(isZip && isUTF8);
                                return false;
                            }
                            setZipUploaded(isZip && isUTF8 && isSize);
                            return isZip && isUTF8 && isSize;
                        }}
                        onRemove={file => {
                            setZipFileList(prev => prev.filter(f => f.uid !== file.uid));
                            setZipUploaded(false);
                        }}
                        defaultFileList={[]}
                        maxCount={1}
                        listType="picture"
                        multiple={false}
                        accept=".zip"

                    >
                        {/*<Button icon={<FileZipOutlined />}>{t('selectZIP')}</Button>*/}
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">{t('selectMaster')}</p>
                        <div className='d-none d-lg-block d-sm-none'>

                            <p className="ant-upload-hint">
                                {t('ZipFileNote')}
                            </p>
                        </div>

                    </Dragger>
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button type="primary"  onClick={()=>{
                            setZipUploaded(false);
                            setZipFileList([]);
                            setStep(step-1)
                        }} >{t('previous')}</Button>
                        <Button type="primary"  onClick={()=>{

                            setStep(step+1)
                        }} disabled={!zipUploaded }>{t('next')}</Button>
                    </Space>
                </Form.Item>

            </>
        },{
            title: <p className='d-none d-lg-block d-sm-none'> Tracklist.pdf</p>,
            content:<>
                <Form.Item name="pdf" >
                    <Dragger
                        itemRender={(originNode, file, currFileList) => itemRender(originNode, file, currFileList, setPdfFileList, setPdfUploaded)}
                        showUploadList={pdfUploaded}
                        fileList={pdfFileList}
                        onChange={({ fileList }) => setPdfFileList(fileList)}
                        beforeUpload={(file) => {
                            const isPdf = file.type.includes('pdf');
                            const isUTF8 = checkFileName(file);
                            const isSize = checkFileSize(file);

                            if (!isPdf) {
                                setPdfFileList([])
                                toast.error(t('onlyPDF'),15);
                                setPdfUploaded(isPdf && isUTF8);
                            }
                            setPdfUploaded(isPdf && isUTF8 && isSize);
                            return isPdf && isUTF8 && isSize;
                        }}
                        onRemove={file => {
                            setPdfFileList(prev => prev.filter(f => f.uid !== file.uid));
                            setPdfUploaded(false);
                        }}
                        maxCount={1}
                        multiple={false}
                        accept={".pdf"}
                        listType="picture">
                        {/*<Button icon={<UploadOutlined />}>{t('selectPDF')}</Button>*/}
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">{t('selectPDF')}</p>
                        <ul className='d-none d-lg-block d-sm-none' style={{textAlign:"left"}}>
                            <li><p  className="ant-upload-hint">{t('tracklistNote')}</p></li>
                            <li><p  className="ant-upload-hint">{t('tracklistNote2')}</p></li>
                            {/*link to download the template*/}
                        </ul>
                        <a style={{textAlign:'center'}} href="/assets/files/Tracklisting_vinyl_en.pdf" download="TrackList_SNA.pdf">Download the tracklist template here</a>

                    </Dragger>
                </Form.Item>

        <Form.Item>
            <Space>
                <Button onClick={()=>{
                    setPdfUploaded(false);
                    setPdfFileList([]);
                    setStep(step-1)
                }}>{t('previous')}</Button>
                <SubmitButton  />
            </Space>
        </Form.Item>
        </>
        },
        {
            title:<p className='d-none d-lg-block d-sm-none'>{t('uploading')}</p> ,
            content:
                <div className="container">
                    <div className="row d-flex justify-content-between">
                        <Text className="mb-3 col-2">Tracklist</Text>
                        <Progress
                            className="col-9"
                            percent={uploadProgress}
                            status={errorFile}
                            size="small"
                            strokeColor={errorFile ? '#f5222d' : {
                                '0%': '#108ee9',
                                '100%': '#87d068',
                            }}
                        />

                    </div>

                    <div className="row d-flex justify-content-between">
                        <Text className="d-block mb-3 col-2">Master</Text>
                        <Progress
                            className="col-9"
                            percent={uploadProgressZIP}
                            status={errorFile}
                            size="small"
                            strokeColor={errorFile ? '#f5222d' : {
                                '0%': '#108ee9',
                                '100%': '#87d068',
                            }}
                        />
                    </div>



                    <Text className="d-block mb-3">{speed} Mo/s</Text>

                    <Form.Item>


                        <Space direction={"vertical"}>

                            {uploadProgress === 100 && uploadProgressZIP === 100 && !uploadSuccess && !errorFile && (
                                <>
                                    <Spin indicator={antIcon} />
                                    Vérification des fichiers ( cette opération peut prendre un peu de temps )
                                </>
                            )}


                        { uploadSuccess ? (
                            <>
                                <Text className="d-block mb-3">{t('uploadSuccess')}</Text>
                                <Button onClick={reinit}>{t('resetForm')}</Button>
                            </>

                        ) :

                            errorFile ? (
                                <>
                                    <Text className="d-block mb-3">{t('uploadError')}</Text>
                                    <Button onClick={reinit}>{t('resetForm')}</Button>
                                </>
                            ) :
                                !uploadProgress === 100 && !uploadProgressZIP === 100 &&
                                    <Button onClick={
                                    () => {
                                        cancelUploadHandler(()=>{
                                            setStep(step-1)
                                        })}} disabled={!cancelUpload}>{t('cancel')}
                                </Button>
                            }
                      </Space>
                    </Form.Item>

                </div>
        },
    ]

    function submitFiles() {
        return async () => {
            const zipFile = zipFileList[0];
            const pdfFile = pdfFileList[0];
            setStep(step + 1);
            // Append the files to formData
            if (zipFile) {
                formData.append('zip', zipFile.originFileObj, zipFile.name);
            }
            if (pdfFile) {
                formData.append('pdf', pdfFile.originFileObj, pdfFile.name);
            }
            formData.append('reference', title);
            submitPDF(formData)

            console.log("reponse front", response);

        };
    }

    return (
        <Form
            form={form}
            onFinish={submitFiles()}
            layout="vertical"
            className="rounded-5 p-3 w-50 h-75">
            <Steps
                className='d-none d-sm-none d-md-flex d-lg-flex justify-content-center align-items-center m-0'
                size="small"
                current={step}
                items={stepsItem}
            />
            <div style={{height:"100%"}} className="d-flex d-md-flex flex-column justify-content-center p-lg-5 p-sm-0">{stepsItem[step].content}</div>
        </Form>
    );
};